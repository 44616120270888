(function($){

	$(document).ready(function() {
		$('a.gallery').featherlightGallery({
			previousIcon: '<div class="chevron-shell"> <div class="chevron"></div> </div>',     /* Code that is used as previous icon */
			nextIcon: '<div class="chevron-shell"> <div class="chevron"></div> </div>',         /* Code that is used as next icon */
			galleryFadeIn: 100,          /* fadeIn speed when slide is loaded */
			galleryFadeOut: 300          /* fadeOut speed before slide is loaded */
		});
	})

})(jQuery);