/**
 * Mobile Navigation Animation
 *
 * @since 1.0.0
 * @package paradry
 */

(function($) {
	$(document).ready(function() {

		// things
		var $window         = $( window );
		var header          = $( 'header[role=banner]' );
		var headerBanner	= $( 'header.banner' );
		var nav             = $( 'nav', header );
		var navToggle       = $( '#menu-toggle', header );
		var adminBar        = $( '#wpadminbar' );
		var parentMenuItems = $( '.menu-item-has-children' );
		var subMenuToggles;

		// heights
		var windowHeight;
		var headerHeight;
		var headerBannerHeight
		var navHeight;
		var adminBarHeight;

		// init stuff
		maybeScrollY();
		addSubmenuToggles();
		// hasSrolled();

		// bind events
		$window.resize( reApplyOffset );
		$window.resize( maybeScrollY );
		navToggle.click( toggleMenu );
		subMenuToggles.click( toggleSubmenu );


		function hasSrolled() {
			var headerBannerHeight = headerBanner.height();

			if ( $(document).scrollTop() > headerBannerHeight ) {
				header.addClass( 'scrolled' );
			} else {
				header.removeClass( 'scrolled' );
			}
		}

		/**
		 * Scroll from top detection
		 */
		$(document).on( 'scroll', function() {

			var headerBannerHeight = headerBanner.outerHeight();

			if ( $(document).scrollTop() > 50 ) {
				header.addClass( 'scrolled' );
			} else {
				header.removeClass( 'scrolled' );
			}
		});


		/**
		 * Calculate menu offset
		 *
		 * @since 1.0.0
		 */
		function getOffset() {
			return headerHeight + adminBarHeight;
		}


		/**
		 * Recalculate menu offset if toggled
		 *
		 * @since 1.0.0
		 */
		function reApplyOffset() {

			if ( nav.hasClass( 'toggled' ) ) {
				nav.css({ top: getOffset() });
			}
		}


		/**
		 * Toggler: toggle class and position menu
		 *
		 * @since 1.0.0
		 */
		function toggleMenu() {

			nav.toggleClass( 'toggled' );

			// set offset
			if ( nav.hasClass( 'toggled' ) ) {
				nav.css({ top: getOffset() });
			} else {
				nav.css({ top: -getOffset() + -navHeight });
			}
		}


		/**
		 * Get the header, nav, and admin bar
		 * heights
		 *
		 * @since 1.0.0
		 */
		function getHeights() {
			windowHeight   = $window.height();
			headerHeight   = header.outerHeight();
			navHeight      = nav.outerHeight();
			adminBarHeight = adminBar.outerHeight();
		}


		/**
		 * Maybe scroll overflow-y
		 *
		 * If the height of the menu goes beyond
		 * what's visible on the screen, allow
		 * some scrolling
		 *
		 * @since 1.0.0
		 */
		function maybeScrollY() {

			// reset scroll-y so height is
			// calculated correctly
			nav.removeClass( 'scroll-y' );

			// get our heights
			getHeights();

			// calculate usable height
			var usableHeight = windowHeight - headerHeight - adminBarHeight;

			// compare
			if ( usableHeight < navHeight ) {
				nav.addClass( 'scroll-y' );
			}
		}


		/**
		 * Add mobile submenu dropdown toggles,
		 * then bind them to toggles var
		 *
		 * @since 1.0.0
		 */
		function addSubmenuToggles() {

			parentMenuItems.append(
				'<button class="toggle-sub"><span class="screen-reader-text">Menu</span></button>'
			);

			subMenuToggles = $( '.toggle-sub', parentMenuItems );
		}


		/**
		 * Toggle mobile submenu
		 *
		 * @since 1.0.0
		 */
		function toggleSubmenu() {
			if ( $window.width() > 1000 ) { return; }

			var li = $(this).parent();
			var all_other_li = $( 'li.menu-item-has-children.sub-toggled' ).not( li ).not( li.parents() );
			// Close all other submenus that are not parent submenu
			all_other_li.toggleClass( 'sub-toggled' );
			// Toggle current submenu
			li.toggleClass( 'sub-toggled' );

			// double-check the scroll-y
			maybeScrollY();
		}

	}); // document.ready
})(jQuery);