/**
 * Banner video config
 *
 * @since   1.0.0
 * @package bigblue
 */

var video, tag, firstScriptTag, player;

(function() {

	video = document.getElementById( 'banner-video' );

	// sanity check
	if ( ! video ) return;

	videoID        = video.dataset.videoId;
	tag            = document.createElement( 'script' );
	tag.src        = "https://www.youtube.com/iframe_api";
	firstScriptTag = document.getElementsByTagName( 'script' )[0];

	firstScriptTag.parentNode.insertBefore( tag, firstScriptTag );

})();


/**
 * Configure the video
 *
 * @since 1.0.0
 */
function onYouTubeIframeAPIReady() {
	player = new YT.Player( video.id, {
		height     : '1080',
		width      : '1920',
		videoId    : videoID,
		playerVars : {
			rel            : 0,
			controls       : 0,
			mute           : 1,
			modestbranding : 0,
			autohide       : 0,
			showinfo       : 0,
			wmode          : 'transparent',
			branding       : 0,
			origin         : window.location.origin,
			enablejsapi    : 1,
		},
		events     : {
			onReady       : onBannerVideoReady,
			onStateChange : onBannerVideoStateChange,
		}
	});
}


/**
 * Play the video
 *
 * @param {event} event
 */
function onBannerVideoReady( event ) {
	event.target.setVolume(0);
	event.target.playVideo();
}


/**
 * Maybe restart the video
 *
 * @param {integer} state  0 is "ended"
 */
function onBannerVideoStateChange( state ) {

	if ( state.data === 0 ) {
		state.target.playVideo();
	}
}
