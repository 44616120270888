(function($) {

	$('.home-carousel').flickity({
		// options
		cellAlign: 		'center',
		setGallerySize: false,
		contain: 		true,
		wrapAround: 	true,
		pageDots: 		false,
		autoPlay: 		7000
	});

	// destroy carousel if featherlight box closed
	if ( typeof $.featherlight !== 'undefined' && $.isFunction($.featherlight) ) {
		$('body.page-template-page-portfolio a[href*="feather-"]').featherlight({
			targetAttr: 'href',
			beforeClose: function(event){
				var $carousel = $('.featherlight-carousel').flickity();
				$carousel.flickity('destroy');
				return;
			}
		});
	}

	$('body.page-template-page-portfolio section.portfolio-gallery .card-wrapper').on('click', function() {

		setTimeout(function(){
          $('.featherlight-carousel').flickity({
          	// options
          	cellAlign: 		'center',
          	setGallerySize: false,
          	contain: 		true,
          	wrapAround: 	true,
          	pageDots: 		false,
          	autoPlay: 		7000
          });
        }, 200);

	});

	$('.project-image-carousel').flickity({
		// options
		cellAlign: 			'center',
		setGallerySize:     false,
		contain: 			true,
		wrapAround: 		false,
		pageDots: 			false,
		prevNextButtons: 	true,
		imagesLoaded: 		false
	});

	$(document).ready(function () {
		setTimeout(function(){
			$('.clients .client-slider').flickity({
				// options
				cellAlign: 			'center',
				setGallerySize:     false,
				contain: 			true,
				wrapAround: 		false,
				pageDots: 			false,
				prevNextButtons: 	false,
				imagesLoaded: 		true,
				autoPlay: 			5000
			});
		}, 500);
	});


	// Service Single page sliders
	$('.service-image-carousel').flickity({
		// options
		cellAlign: 			'left',
		setGallerySize:     false,
		contain: 			true,
		wrapAround: 		false,
		pageDots: 			false,
		prevNextButtons: 	false,
		imagesLoaded: 		false
	});

	$('.service-image-nav').flickity({
		// options
		asNavFor: '.service-image-carousel',
		cellAlign: 			'left',
		setGallerySize:     false,
		contain: 			true,
		wrapAround: 		false,
		pageDots: 			false,
		prevNextButtons: 	false,
		imagesLoaded: 		false
	});

})(jQuery);
