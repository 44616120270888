(function($) {

	// Banner toggle equipment menu
	$('header.banner a.banner-equipment-nav').click(function () {
		// alert('click happened');
		$('header.banner .banner-equipment-nav').toggleClass('flip-icon');
		$('header.banner .equipment-menu').toggleClass('show-equipment');
		$('header.banner').toggleClass('equipment-open');
	});

	$('#gencor-training .training-navigation-wrapper .training-nav li:nth-child(n+2) > a').hover(function() {
		$('#gencor-training .training-navigation-wrapper .training-nav li:first-of-type > a').toggleClass('remove-hover');
	});

	// Guide Image
	function proofguide(proof) {
	    var button = $('<div id="bcorr-guide-toggle">');
	    var guide = $('<div id="bcorr-guide" class="hide">');
	    var img = $('<img>');
	    img.attr('src', proof);
	    img.appendTo(guide);

	    guide.appendTo('body');
	    button.appendTo('body');

	    $("#bcorr-guide-toggle").on('click', function (event) {
	        $("#bcorr-guide").toggleClass("hide");
	    });
	    $("#bcorr-guide img").on('click', function (event) {
	        $("#bcorr-guide").toggleClass("light");
	    });

	    $(window).resize(function () {
	        var screenImage = $("#bcorr-guide img");

	        // Create new offscreen image to test
	        var theImage = new Image();
	        theImage.src = screenImage.attr("src");

	        // Get accurate measurements from that.
	        var imageWidth = theImage.width;
	        if (imageWidth > $(window).width()) {
	            $("#bcorr-guide img").css("-webkit-filter", "invert(1)");
	        } else {
	            $("#bcorr-guide img").css("-webkit-filter", "none");
	        }
	    });
	}
	// uncomment to show guide
	// proofguide(' /wp-content/themes/paradry/assets/guide.jpg');

})(jQuery);