/**
 * Ajax filtering
 *
 * @since 1.0.0
 * @package paradry
 */

(function($) {

	// to keep the screen from moving around a bunch on ajax load
	var sectionHeight = $('section.project-grid').height();
	// $('section.project-grid').css('min-height',sectionHeight);

	$('nav.pagination ul a.page-numbers').on('click', function(event) {
		// Prevent default action - opening tag page
		if (event.preventDefault) {
		    event.preventDefault();
		} else {
		    event.returnValue = false;
		}
		$.ajax({url: $(this).prop('href'), success: function(d) {
	        var page = $(d).find('.project-grid .wrap').html();
	        $('.project-grid .wrap').html(page);
	    }});
	});

	// Ajax for desktop links ... commented out because they decided to want individual archive pages
	// $('section.category-filter .categories ul.category-links li a').on( 'click' ,function(event) {

	//     // Prevent default action - opening tag page
	//     if (event.preventDefault) {
	//         event.preventDefault();
	//     } else {
	//         event.returnValue = false;
	//     }

	//     // Get tag slug from href
	//     var seleceted_taxonomy = $(this).attr('href');
	//     var slug = seleceted_taxonomy.split('/');
	//         slug = slug[slug.length - 2];
	//         seleceted_taxonomy = slug;

	//     // Alter display properties of Featured Projects list to match slug
	//     $('.featured').each(function (index, value) {
	//     	var category = $(this).attr('data-category');
	//     	var isDefault = $(this).hasClass('default');

	//     	// Show Featured Project for category
	//     	if ( slug == category ) {
	//     		$(this).removeClass('hide');
	//     	}
	//     	// If no category chosen, show Default Featured Project
	//     	else if ( slug == 'projects' && isDefault ) { 
	//     		$(this).removeClass('hide');
	//     	}
	//     	// Otherwise, show nothing
	//     	else {
	//     		$(this).addClass('hide');
	//     	}
	//     });

	//     // After user click on tag, fade out list of posts
	//     $('.project-grid .wrap').fadeOut();

	//     data = {
	//         action: 'filter_posts', // function to execute
	//         afp_nonce: afp_vars.afp_nonce, // wp_nonce
	//         taxonomy: seleceted_taxonomy, // selected tag
	//         };

	//     $.post( afp_vars.afp_ajax_url, data, function(response) {

	//         if( response ) {
	//             // Display posts on page
	//             $('.project-grid .wrap').html( response );
	//             $('nav.pagination ul a.page-numbers').on('click', function(event) {
	//             	// Prevent default action - opening tag page
	//             	if (event.preventDefault) {
	//             	    event.preventDefault();
	//             	} else {
	//             	    event.returnValue = false;
	//             	}
	//             	$.ajax({url: $(this).prop('href'), success: function(d) {
	//                     var page = $(d).find('.project-grid .wrap').html();
	//                     $('.project-grid .wrap').html(page);
	//                 }});
	//             });
	//             // Restore div visibility
	//             $('.project-grid .wrap').fadeIn();
	//         };
	//     });
	// });

	// Ajax for mobile dropdown
	$('section.category-filter .categories select').change( function(event) {

	    // Prevent default action - opening tag page
	    if (event.preventDefault) {
	        event.preventDefault();
	    } else {
	        event.returnValue = false;
	    }

	    // Get tag slug from option text
	   	var seleceted_taxonomy = $(this).find('option:selected').text().toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');
	   	if ( seleceted_taxonomy == 'all-categories' ) {
	   		window.location.href = "/projects/";
	   	} else {
	   		window.location.href = "/projectcategory/" + seleceted_taxonomy;
	   	}

	    // After user click on tag, fade out list of posts
	    // $('.project-grid .wrap').fadeOut();

	    // data = {
	    //     action: 'filter_posts', // function to execute
	    //     afp_nonce: afp_vars.afp_nonce, // wp_nonce
	    //     taxonomy: seleceted_taxonomy, // selected tag
	    //     };

	    // $.post( afp_vars.afp_ajax_url, data, function(response) {

	    //     if( response ) {
	    //     	console.log(response);
	    //         // Display posts on page
	    //         $('.project-grid .wrap').html( response );
	    //         // Restore div visibility
	    //         $('.project-grid .wrap').fadeIn();
	    //     };
	    // });
	});
})(jQuery);