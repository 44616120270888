(function($) {

	window.sr = ScrollReveal();

	sr.reveal('.title-button .wrap', { duration: 1500 } );

	sr.reveal('.card-link', { duration: 1500 }, 100);

	sr.reveal('.services .left_image .col-2', { origin: 'right', distance: '50%', opacity: 1, scale: 1, duration: 2500 });
	sr.reveal('.services .right_image .col-2', { origin: 'left', distance: '50%', opacity: 1, scale: 1, duration: 2500 });
	sr.reveal('.services .background-grid', { origin: 'right', distance: '100%', scale: 0.9, duration: 2000 });

	sr.reveal('.portfolio-slider .wrap', { duration: 1500 });

	sr.reveal('.title-button-background .wrap', { duration: 1500 });

})(jQuery);
